div#clients {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: #74899d;
  background-image: url("../../../images/contactus/clients.jpg"); */
}
div#clientsHeader {
  width: 100%;
  text-align: center;
  /* margin: 45px 0px 45px 0px; */
}
div#clientsHeader > .heading {
  color: var(--primaryColor) !important;
}
.clientList {
  margin-top: 30px;
  margin-bottom: 30px;
}
.clientProfile {
  width: 95% !important;
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-bottom: 45px;
  overflow: hidden;
  position: relative;
}
.clientProfile > img {
  width: 70%;
  border: 1px solid #dddddd;
  padding: 15px 0px;
  border-radius: 5px;
  filter: drop-shadow(2px 4px 6px #ccc);
  background: #fff;
  margin: 15px 0px;
}
.slick-slide > div {
  width: 100%;
  display: flex;
  justify-content: center;
}
