#services {
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: #002fa6;
  background-image: url("../../../images/services/service4.jpg");
}
#services > .container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#services > .container > .serviceContainer {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  margin-bottom: 35px;
}
div#services > .container > .serviceContainer > .textContent {
  width: 45%;
  color: white;
}
div#services > .container > .serviceContainer > .imageBox {
  text-align: center;
  width: 50%;
  margin-top: 10px;
  animation: fadeInDown 3.5s linear infinite;
}
.imgWidth {
  width: 480px;
}
#subContainer > .imageContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}
#subContainer > .imageContainer > .serviceContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  /* box-shadow: 0px 0px 10px 0px #000000; */
  width: 30%;
}
.serviceContent > .imageContent > .subHead {
  font-size: 1rem;
  font-weight: 500;
  color: white;
  margin-bottom: 0px;
  /* background-color: red; */
}
.serviceContent > .imageContent > .imgText {
  font-size: 0.9rem;
  color: white;
  text-align: center;
}
.imageContainer > .serviceContent > .imageContent {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

@keyframes fadeInDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
