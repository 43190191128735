div#welcomeContainer {
  background-image: url(../../../images/welcome/2.jpeg);
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
div#welcomeContainer > h1 {
  color: white;
  font-size: 3.2rem;
  text-shadow: 1px 2px 10px #000;
  z-index: 9999;
  border-radius: 5px;
  padding: 0px 30px;
  position: relative;
  top: 15%;
}
#continueText {
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 16px !important;
  font-weight: 500;
  cursor: pointer;
}

/* Text Reveal Animation */
#welcomeContainer > h1,
#welcomeContainer > h1::after {
  animation-delay: var(--animation-delay, 2s);
  animation-iteration-count: var(--iterations, 1);
  animation-duration: var(--duration, 800ms);
  animation-fill-mode: both;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}
#welcomeContainer > h1 {
  --animation-delay: var(--delay, 0);
  --animation-duration: var(--duration, 800ms);
  --animation-iterations: var(--iterations, 1);
  position: relative;
  font-size: 10vw;
  animation-name: clip-text;
  color: #fff;
  white-space: nowrap;
  cursor: default;
}

@keyframes clip-text {
  from {
    clip-path: inset(0 100% 0 0);
  }
  to {
    clip-path: inset(0 0 0 0);
  }
}

div#continueBtnContainer {
  width: 100%;
  position: relative;
  bottom: -9%;
}

#continueBtn span {
  position: absolute;
  bottom: 39px;
  left: 50%;
  z-index: 2;
  display: inline-block;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  color: #fff;
  font: normal 400 20px/1 "Josefin Sans", sans-serif;
  letter-spacing: 0.1em;
  text-decoration: none;
  transition: opacity 0.3s;
  cursor: pointer;
  /* box-shadow: -7px 6px 16px 0px #03a9f4; */
}
#continueBtn span:hover {
  opacity: 0.5;
}
#continueBtn span {
  position: absolute;
  top: 26px;
  left: 50%;
  width: 24px;
  height: 24px;
  margin-left: -12px;
  border-left: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
  animation: sdb05 1.5s infinite;
  box-sizing: border-box;
}
@-webkit-keyframes sdb05 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
@keyframes sdb05 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: rotate(-45deg) translate(-20px, 20px);
    opacity: 0;
  }
}
