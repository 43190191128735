div#contactUs {
  width: 100%;
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: #74899d;
  background-image: url("../../../images/contactus/1.jpg");
}
div#contactUs h2.heading {
  text-shadow: 1px 2px 3px #000;
}
div#contactUs h4.subHeading {
  text-shadow: 1px 2px 3px #000;
}
div#contactUs .contactContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 50px;
}
div#contactUs .content {
  color: white;
  display: flex;
  flex-direction: column;
  width: 50%;
}
div#contactUs .detailsContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
div#contactUs .details {
  width: 43%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  color: black;
  border-radius: 10px;
  padding: 30px 10px;
}
div#contactUs .details h4 {
  margin-top: 5px;
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: 500;
}
div#contactUs .details p {
  margin-bottom: 0;
  margin-top: 0;
  font-size: 14px;
}
div#contactUs .textField {
  /* margin-bottom: 10px; */
}
div#contactUs .textField input {
  padding: 10px;
  color: black;
  padding-left: 0px;
  padding-top: 2px;
}
div#contactUs .textField label {
  font-size: 14px;
  color: black;
}
div#contactUs .textField textarea {
  padding-left: 0px;
}
.textField > div > div::after {
  border-bottom: 1px solid black;
}
div#contactUs .btnContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 15px;
}
div#contactUs.content h3 {
  font-size: 26px;
  line-height: 1;
  margin-top: 0;
}
div#contactUs .captchaFeild {
  margin-top: 15px;
}
div#contactUs .btn {
  width: 50%;
  color: white;
  background: black;
  font-weight: bold;
  padding: 12px 0px;
}
div#contactUs .btn span {
  font-size: 12px;
}
div#contactUs.mapContainer {
  width: 52%;
}
div#contactUs.icon {
  padding-bottom: 10px;
  font-size: 35px !important;
}
div#contactUs a.link {
  font-size: 14px;
}
div#contactUs.content h3 {
  margin-top: 0;
}
div#contactUs .inputContainer {
  background: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 40%;
  border-radius: 10px;
  padding: 15px 30px;
}
div#contactUs .inputContainer h3 {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: center;
  color: black;
  font-weight: 500;
}
.errorMsg {
  color: red !important;
  font-size: 12px !important;
}
div#contactUs .inputContainer .textField p {
  visibility: hidden;
  margin-top: 0;
  margin-bottom: 0;
}
div#contactUs .inputContainer .textField.errorMsg p {
  visibility: visible;
  margin-top: 0;
  margin-bottom: 0;
}
div#contactUs #frmResult {
  font-size: 14px;
  color: green;
  display: flex;
  justify-content: center;
  margin-bottom: 6px;
}
