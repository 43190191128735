:root {
  --primaryColor: #086ad8;
  --secondaryColor: #000;
  overflow-x: hidden;
}
html,
body {
  max-width: 100%;
  min-width: 100%;
  margin: 0;
  scroll-behavior: smooth;
  font-family: "Poppins", sans-serif;
}
a.link {
  position: relative;
  color: inherit;
  font-size: 14px;
  text-decoration: none;
  color: var(--primaryColor);
}
a.link:hover {
  color: #02cafc !important;
}

div#root > div {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.container {
  width: 90%;
}

/* Colors */
.white {
  color: #fff !important;
}

/* Common Css */
.dummyDiv {
  height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.responsiveImg {
  width: 100%;
}
/* Main Heading */
h2.heading {
  color: white;
  font-size: 3.2rem;
  font-weight: 500;
  margin-bottom: 0;
}
/* Sub Heading ( Comes below Heading ) */
h4.subHeading {
  color: #4d4d4d;
  margin: 0px;
  font-size: 1.2rem;
  font-weight: 400;
}
/* Paragraph Text */
p.pText {
  font-size: 1.1rem;
  text-align: justify;
}
.textCenter {
  text-align: center;
}

.width100 {
  width: 100% !important;
}
.width75 {
  width: 75% !important;
}
.width50 {
  width: 50% !important;
}
.width30 {
  width: 30% !important;
}

.mB0 {
  margin-bottom: 0px !important;
}
.mB10 {
  margin-bottom: 10px !important;
}
.mB20 {
  margin-bottom: 20px !important;
}
.mT10 {
  margin-top: 10px !important;
}
.mT15 {
  margin-top: 15px !important;
}
.mT20 {
  margin-top: 20px;
}
.mT0 {
  margin-top: 0;
}
.mR15 {
  margin-right: 15px !important;
}
.mL0 {
  margin-left: 0px !important;
}
.pT15 {
  padding-left: 15px;
}
.pL10 {
  padding-left: 10px;
}

/* Media Queries */

/* Tab Devices */
@media screen and (max-width: 1024px) {
  div#welcomeContainer > h1 {
    color: white;
    font-size: 3rem;
  }
  div#aboutUs .subContent .titleText > h1 {
    font-size: 3.9rem;
  }
  div#aboutUs .subContent .description > span {
    font-size: 1rem;
  }
}

/* Mobile Devices */
@media screen and (max-width: 768px) {
  /* Welcome */
  div#welcomeContainer {
    background-position-x: -445px;
  }
  div#welcomeContainer > h1 {
    color: white;
    font-size: 1.9rem;
    padding: 0 10px;
    word-break: inherit;
    white-space: break-spaces;
    padding-bottom: 0;
    line-height: 85px;
    margin: 0;
    position: relative;
    top: 25%;
    text-align: center;
    font-weight: 600;
  }
  div#continueBtn {
    bottom: 8% !important;
  }
  /* Services */
  div#services h2 {
    font-size: 1.3rem;
  }
  div#services .pText,
  div#services h4 {
    font-size: 0.9rem !important;
    /* text-align: center !important; */
  }
  div#services .imgText {
    font-size: 0.7rem;
  }
  div#services > .container > .serviceContainer > .imageBox {
    display: none;
  }
  /* div#services .container .imageBox > .imgWidth {
    width: 370px;
    display: none;
  } */
  div#services .textContent {
    width: 100% !important;
  }
  div#services .imageContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 2.5rem;
  }
  div#services .imageContainer > .serviceContent {
    display: flex;
    width: 100%;
  }
  div#services .imageContent {
    width: 90%;
  }
  /* About Us */
  div#aboutUs .imageContainer {
    display: none;
  }
  div#aboutUs .content {
    width: 100%;
  }
  div#aboutUs .content {
    width: 100%;
  }
  div#aboutUs .content > h2 {
    font-size: 1.3rem;
  }
  div#aboutUs .content > .pText {
    font-size: 0.9rem !important;
  }
  div#contactUs a.link {
    font-size: 1rem;
  }
  /* Contact Us */
  div#contactUs .contactContainer {
    width: 100%;
  }
  div#contactUs h2.heading {
    font-size: 1.3rem;
  }
  div#contactUs h4.subHeading {
    font-size: 0.8rem !important;
  }
  div#contactUs .content {
    width: 100%;
    margin-top: 25px;
  }
  div#contactUs .detailsContainer {
    margin-bottom: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  div#contactUs .details {
    width: 97%;
    margin-bottom: 15px;
    padding-top: 40px;
    padding-bottom: 40px;
  }
  div#contactUs .icon {
    font-size: 40px !important;
  }
  div#contactUs .details h4 {
    font-size: 1rem !important;
  }
  div#contactUs .details p {
    font-size: 1rem !important;
  }
  div#contactUs .inputContainer {
    width: 82%;
    margin-top: 15px;
  }
  div#contactUs .inputContainer h3 {
    font-size: 1rem;
  }
  div#contactUs #frmResult {
    font-size: 9px;
    font-size: 400 !important;
  }
  div#contactUs p {
    font-size: 0.7rem !important;
  }
  div#contactUs .captchaFeild {
    margin-left: -5px;
  }
  div#contactUs .btn span {
    font-size: 16px;
    letter-spacing: 2px;
    font-weight: 500;
  }
  .container {
    max-width: 720px;
  }
  /* Clients */
  div#clientsHeader > h2.heading {
    font-size: 1.5rem;
  }
  div#clientsHeader h4.subHeading {
    font-size: 1rem !important;
    margin-bottom: -10px;
    margin-top: 5px !important;
  }
  .clientProfile {
    width: 100% !important;
  }
  .clientProfile > img {
    width: 80% !important;
  }
  /* Header */
  div#navBar > .logo > img {
    width: 100% !important;
  }
  /* Footer */
  div#footer > .container > .footerContainer {
    display: flex;
    flex-direction: column;
  }
  div#footer h4 {
    font-size: 0.9rem;
  }
  div#footer a,
  div#footer p {
    font-size: 0.7rem !important;
  }
  div#footer .footerContent {
    width: 90%;
  }
  div#footer .mT23 {
    margin-top: 23px;
  }
  div#footer .footerContent > #iconContainer {
    display: flex;
    justify-content: space-between;
    width: 45%;
    margin-bottom: 23px;
  }
  div#footer .footerContainer ul,
  .subHeading {
    margin-top: 0px !important;
  }
  div#footer > .container .width100 {
    width: 100% !important;
    align-items: center;
  }
  div#footer > .container .webLogo > img {
    margin-top: 0px;
    margin-bottom: 15px;
    margin-left: auto;
    margin-right: auto;
  }
  div#footer .footerContent.webLogo {
    width: 100%;
  }
  div#bottomContent {
    padding: 10px 0px;
  }
  /* navbar css for mobile start */
  .menuIconBtn {
    display: block;
  }
  .menuBar {
    min-height: 60px;
  }
  div#navBar > .logo {
    width: 24%;
  }
  .menuBar ul {
    position: absolute;
    width: 100%;
    height: calc(100vh - 60px);
    left: 0;
    top: 60px;
    flex-direction: column;
    align-items: center;
    /* border-top: 1px solid #444; */
    background-color: rgba(0, 0, 0, 0.75);
    max-height: 0;
    overflow: hidden;
    transition: 0.3s;
  }
  .menuBar ul li {
    width: 100%;
    text-align: center;
  }
  /* .menuBar ul li Button {
    padding: 25px;
  } */
  .menuBar ul li Button {
    width: 100%;
    color: #00eaff;
    margin: auto;
    padding: 25px;
    font-weight: 500;
    background-color: #3f51b500 !important;
  }
  .menuBar ul li Button:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  .menuBar ul.open {
    max-height: 100vh;
    overflow: visible;
  }
  .intro-area h2 {
    font-size: 36px;
    margin-bottom: 15px;
  }
  div#header {
    background-color: rgba(0, 0, 0, 0.75);
  }
  div#header.aboutusMenu
    > #headerContainer
    > #navBar
    > .menuBar
    > ul
    > li
    > .menuBtn {
    color: #00eaff;
  }
  div#header.ourClientMenu
    > #headerContainer
    > #navBar
    > .menuBar
    > ul
    > li
    > .menuBtn {
    /* background: red !important; */
    color: #00eaff;
  }
  /* Privacy Policy */
  div#privacyPolicy .effectiveDt {
    font-size: 0.8rem;
  }
  div#privacyPolicy .policyContainer .heading {
    font-size: 1.3rem;
  }
  div#privacyPolicy .policyContainer .pText {
    font-size: 0.9rem !important;
  }
  div#privacyPolicy .policyContainer .content {
    font-size: 0.9rem;
  }
  div#privacyPolicy .policyContainer .subHead {
    font-size: 1rem;
    margin-bottom: 5px;
  }
}
