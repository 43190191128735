div#header {
  transition: all 0.5s;
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 99999;
}
div#navBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
div#navBar > .logo {
  width: 12%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
div#navBar > .logo > img {
  width: 60%;
}
div#menuIcon {
  display: none;
}
div#menuIcon > :hover {
  background-color: rgba(83, 80, 80, 0.534);
}
div#headerContainer > #menuIconContainer {
  display: none;
  width: 100%;
  background-color: #232a43a1;
  align-items: center;
  justify-content: center;
}
div#headerContainer > #menuIconContainer > ul {
  padding: 0px;
  text-align: center;
}
div#headerContainer > #menuIconContainer > ul > li {
  list-style: none;
  line-height: 1cm;
}
div#headerContainer > #menuIconContainer > ul > li > a {
  text-decoration: none;
  color: #fff;
}

div#footer > .container > .footerContainer {
  display: flex;
  color: #fff;
  /* align-items: center; */
  justify-content: space-between;
}
.footerContainer > .footerContent {
  display: flex;
  flex-direction: column;
}
.footerContainer > .footerContent > ul > li .subHeading {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  margin-top: 20px;
}
.footerContainer > .footerContent > ul {
  padding-left: 0px;
  font-size: 13px;
}
.footerContainer > .footerContent > ul > li {
  list-style: none;
  margin-bottom: 10px;
  cursor: pointer;
}
.footerContainer > .footerContent > #iconContainer {
  display: flex;
  justify-content: space-between;
  width: 45%;
  align-items: center;
}
.container > .footerContainer > .footerContent a {
  color: #fff;
  text-decoration: none;
  font-size: 13px;
}
div#footer li:hover,
div#footer li:hover a {
  color: #02cafc;
}
.footerContent > ul > li:hover p {
  color: #fff;
}
.footerContent > ul > li > p {
  cursor: auto;
}
#divider {
  border-bottom: 1px solid grey;
  padding-bottom: 10px;
  color: #fff;
  width: 100%;
}
div#footer > .container > #bottomContent {
  display: flex;
  align-items: center;
  justify-content: center;
}
.footerContent > .webLogo > img {
  margin-top: 70px;
}
div#footer > .container > #bottomContent > p {
  color: #fff;
  margin-right: 5px;
  font-size: 13px;
}
#bottomContent a {
  color: #fff;
  text-decoration: none;
  font-size: 13px;
}
div#header.homeMenu {
  background: #000000a6;
}
div#header.aboutusMenu {
  background: #fff;
  box-shadow: 0px 0px 15px #9b9b9b;
}
div#header.aboutusMenu .menuIconBtn {
  border: 2px solid #000;
}
div#header.aboutusMenu .menuIconBtn > span {
  width: 28px;
  height: 2px;
  background-color: #080808;
  display: block;
  transition: 0.3s;
}
div#header.aboutusMenu .menuIconBtn span:before {
  background-color: #000 !important;
}
div#header.aboutusMenu .menuIconBtn span:after {
  background-color: #000 !important;
}
div#header.aboutusMenu .menuIconBtn.toggler-open span {
  background-color: transparent;
}
/* div#header.homeMenu .scroll-top.visible {
  right: -72px !important;
} */
div#header.ourClientMenu .menuIconBtn {
  border: 2px solid #000;
}
div#header.ourClientMenu .menuIconBtn > span {
  width: 28px;
  height: 2px;
  background-color: #080808;
  display: block;
  transition: 0.3s;
}
div#header.ourClientMenu .menuIconBtn span:before {
  background-color: #000 !important;
}
div#header.ourClientMenu .menuIconBtn span:after {
  background-color: #000 !important;
}
div#header.ourClientMenu .menuIconBtn.toggler-open span {
  background-color: transparent;
}
div#header.servicesMenu {
  background: #002fa6;
  box-shadow: 1px 2px 15px #00103a;
}
div#header.ourClientMenu {
  background: #fff;
  box-shadow: 0px 0px 15px #9b9b9b;
}
div#header.ourClientMenu
  > #headerContainer
  > #navBar
  > #menuIcon
  > #IconButton
  > span
  > svg {
  color: #000 !important;
}
div#header.contactUsMenu {
  background: rgb(0, 0, 0);
  box-shadow: 1px 2px 15px #000;
}
.menuBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.menuBar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  margin-right: -24px;
}
.menuBar ul li Button {
  color: #fff;
  padding: 20px;
  font-family: "Poppins", sans-serif;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: bold;
  background-color: #3f51b500 !important;
}
.menuBar ul li Button:hover {
  background: rgba(255, 255, 255, 0.1);
}
/* navbar regular css end */

/* menuIconBtn css start */
.menuIconBtn {
  border: 2px solid #fff;
  padding: 6px 4px;
  background-color: transparent;
  cursor: pointer;
  height: 35px;
  display: none;
}
.menuIconBtn span,
.menuIconBtn span:before,
.menuIconBtn span:after {
  width: 28px;
  height: 2px;
  background-color: #fff;
  display: block;
  transition: 0.3s;
}
.menuIconBtn span:before {
  content: "";
  transform: translateY(-8px);
}
.menuIconBtn span:after {
  content: "";
  transform: translateY(6px);
}
.menuIconBtn.toggler-open span {
  background-color: transparent;
}
.menuIconBtn.toggler-open span:before {
  transform: translateY(0px) rotate(45deg);
}
.menuIconBtn.toggler-open span:after {
  transform: translateY(-3px) rotate(-45deg);
}
div#header.aboutusMenu
  > #headerContainer
  > #navBar
  > .menuBar
  > ul
  > li
  > .menuBtn {
  color: #000;
}
div#header.ourClientMenu
  > #headerContainer
  > #navBar
  > .menuBar
  > ul
  > li
  > .menuBtn {
  /* background: red !important; */
  color: #000;
}
.footerContent.webLogo {
  justify-content: center;
}
/* Scroll Up */
.scroll-top {
  background: #00eaff;
  border: 0;
  border-radius: 50%;
  bottom: 0;
  cursor: pointer;
  height: 50px;
  margin: 15px;
  opacity: 0.75;
  position: fixed;
  right: -70px;
  transition: right 0.2s ease-in-out;
  width: 50px;
  z-index: 99;
  filter: drop-shadow(2px 4px 6px black);
}
.scroll-top.visible {
  right: 0;
}
.arrow {
  border: solid #000000;
  border-width: 0 3px 3px 0;
  display: inline-block;
  margin-top: 5px;
  padding: 4px;
}
.arrow.up {
  transform: rotate(-135deg);
}
