div#aboutUs {
  width: 100%;
}
div#aboutUs .content {
  width: 50%;
  margin-bottom: 2.5rem;
}
div#aboutUs .container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
div#aboutUs p.pText {
  color: black;
}
div#aboutUs h2.heading {
  color: black;
  font-weight: 500;
}
div#aboutUs .imageContainer {
  position: relative;
  width: 45%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10%;
}
div#aboutUs .imageContainer > div {
  width: 45%;
  margin-bottom: 5%;
}
div#aboutUs .moveUpDownImg {
  position: absolute;
  /* z-index: 99; */
  top: 20%;
  left: 30%;
  width: 45%;
  animation: moveUpDown 5.5s linear infinite;
}
div#aboutUs .fadeInDownImg {
  animation: fadeInDown 3.5s linear infinite;
  width: 100%;
  height: auto;
}

@keyframes fadeInDown {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-30px);
  }
}
@keyframes moveUpDown {
  0%,
  100% {
    transform: translateY(10);
  }
  50% {
    transform: translateY(-30px);
  }
}
