div#privacyPolicy .effectiveDt {
  display: flex;
  width: 100%;
  margin-top: 23px;
}
div#privacyPolicy .policyContainer .heading {
  color: black;
  margin-top: 0px;
}
div#privacyPolicy .effectiveDt .bold {
  font-weight: 600;
  margin-right: 5px;
}
div#privacyPolicy .policyContainer .subHead {
  font-size: 1.3rem;
  font-weight: 500;
  margin-bottom: 5px;
  /* background-color: red; */
}
div#privacyPolicy .policyContainer .pText {
  font-size: 1rem;
}
div#privacyPolicy .policyContainer h5 {
  font-size: 1rem;
  margin-bottom: 5px;
}
div#privacyPolicy .policyContainer .content {
  margin: 0;
  font-size: 1rem;
}
